import React, { useEffect, useState, useCallback } from 'react';
import { Grid, _ } from "gridjs-react";
import { Badge, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";
import PropTypes from "prop-types";
import "gridjs/dist/theme/mermaid.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RbAlert from 'react-bootstrap/Alert';
import { verifyToken } from '../Common/AuthToken';

const UserDataTable = () => {
  const [userErrorMsg, setUserErrorMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [error, setError] = useState(false);
  const [cachedData, setCachedData] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState();
  const storedToken = localStorage.getItem('auth_token');
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [actionModal, setActionModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [statusModal, setStatusModal] = useState(false);
  const [fromStatus, setFromStatus] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        let user = {
          token: storedToken
        }
        let token_res = await verifyToken(user);
        setStatus(token_res.status);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [status, storedToken]);



  const updateCache = useCallback(async () => {
    try {
      const userResponse = await axios.get('/api/getUserdata');
      const activeUsers = userResponse.data.data.filter(user => user.registration_status === 1).map((user) => [
        user.firstname,
        user.lastname,
        user.email,
        user.admin_status,
        user.registration_status
      ]);

      const newUsersData = userResponse.data.data.filter(user => user.registration_status === 0).map((user) => [
        user.firstname,
        user.lastname,
        user.email,
        user.admin_status,
        user.registration_status
      ]);

      setCachedData(activeUsers);
      setNewUsers(newUsersData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true);
      setErrorMsg("Failed to fetch user data");
      setShow(true);
    }
  }, []);

  useEffect(() => {
    updateCache();
  }, [updateCache]);

  const toggleDropdown = (email) => {
    setDropdownOpen(prevState => ({ ...prevState, [email]: !prevState[email] }));
  };

  const getStatusText = (status) => {
    switch (status) {
      case 2:
        return "Admin";
      case 1:
        return "Researcher";
      case 0:
        return "Viewer";
      default:
        return "Unknown";
    }
  };

  const handleStatusChangeClick = (user, newStatus) => {
    const currentStatus = parseInt(user._cells[3].data);
    const email = user._cells[2].data;
    const firstName = user._cells[0].data;

    if (currentStatus === 2) {
      setSelectedUser({ email, firstName });
      setFromStatus(currentStatus);
      setNewStatus(newStatus);
      setStatusModal(true);
      return;
    }
    if (currentStatus === newStatus) {
      toast.warning(`${firstName}'s status is already ${getStatusText(newStatus)}`);
      return;
    }

    setSelectedUser({ email, firstName });
    setFromStatus(currentStatus);
    setNewStatus(newStatus);
    setStatusModal(true);
  };

  const handleAdminStatusChange = async () => {
    if (fromStatus === 2) {
      setStatusModal(false);
      toast.warning("Admin status can't be changed.");
      return;
    }
    try {
      let config = {
        method: 'POST',
        url: '/api/updateUserStatus',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email: selectedUser.email,
          admin_status: newStatus,
        }
      };

      await axios.request(config);
      toast.success(`Admin status of ${selectedUser.firstName} updated from ${getStatusText(fromStatus)} to ${getStatusText(newStatus)} successfully!`);
      setStatusModal(false);
      updateCache();
    } catch (error) {
      toast.error("Failed to update admin status");
    }
  };

  const handleActionClick = (user, action) => {
    const emailCell = user._cells[2].data;
    const firstName = user._cells[0].data;
    setSelectedUser({ ...user, email: emailCell, firstName });
    setActionType(action);
    setActionModal(true);
  };

  const handleAction = async () => {
    if (actionType === 'accept') {
      try {
        let config = {
          method: 'POST',
          url: '/api/updateUserRegStatus',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            email: selectedUser.email,
            reg_status: 1,
          }
        };

        await axios.request(config);
        toast.success(`${selectedUser.firstName} accepted successfully!`);
        setActionModal(false);
        updateCache();
      } catch (error) {
        toast.error(`Failed to accept ${selectedUser.firstName}`);
      }
    } else if (actionType === 'reject') {
      try {
        let config = {
          method: 'POST',
          url: '/api/deleteUserData',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            email: selectedUser.email,
          }
        };

        await axios.request(config);
        toast.success(`${selectedUser.firstName} rejected and deleted successfully!`);
        setActionModal(false);
        updateCache();
      } catch (error) {
        toast.error(`Failed to reject and delete ${selectedUser.firstName}`);
      }
    } else {
      setActionModal(false);
    }
  };

  const columns = [
    {
      name: "First Name",
      formatter: (cell) => {
        return _(
          <span className='fw-600 text-primary'>{cell}</span>
        );
      }
    },
    "Last Name",
    "E-mail",
    {
      name: "Admin Status",
      formatter: (cell, row) => {
        let statusText = "";
        let badgeColor = "";

        switch (cell) {
          case 2:
            statusText = "Admin";
            badgeColor = "success";
            break;
          case 1:
            statusText = "Researcher";
            badgeColor = "info";
            break;
          case 0:
            statusText = "Viewer";
            badgeColor = "secondary";
            break;
          default:
            statusText = "Unknown";
            badgeColor = "warning";
        }

        if (cell === 2) {
          return _(
            <Button color={badgeColor} onClick={() => handleStatusChangeClick(row, cell)}>
              {statusText}
            </Button>
          );
        }

        return _(
          <ButtonDropdown isOpen={dropdownOpen[row._cells[2].data]} toggle={() => toggleDropdown(row._cells[2].data)}>
            <DropdownToggle caret color={badgeColor}>
              {statusText}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleStatusChangeClick(row, 2)}>Admin</DropdownItem>
              <DropdownItem onClick={() => handleStatusChangeClick(row, 1)}>Researcher</DropdownItem>
              <DropdownItem onClick={() => handleStatusChangeClick(row, 0)}>Viewer</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        );
      }
    }
  ];

  const newUsersColumns = [
    "First Name",
    "Last Name",
    "E-mail",
    {
      name: "Admin Status",
      formatter: (cell, row) => {
        let statusText = "";
        let badgeColor = "";

        switch (cell) {
          case 2:
            statusText = "Admin";
            badgeColor = "success";
            break;
          case 1:
            statusText = "Researcher";
            badgeColor = "info";
            break;
          case 0:
            statusText = "Viewer";
            badgeColor = "secondary";
            break;
          default:
            statusText = "Unknown";
            badgeColor = "warning";
        }

        return _(
          <Badge color={badgeColor}>{statusText}</Badge>
        );
      }
    },
    {
      name: "Actions",
      formatter: (cell, row) => {
        return _(
          <>
            <Button color="success" size="sm" onClick={() => handleActionClick(row, 'accept')}>Accept</Button>{' '}
            <Button color="danger" size="sm" onClick={() => handleActionClick(row, 'reject')}>Reject</Button>
          </>
        );
      }
    }
  ];

  return (
    <div>
      <h5 className='fw-bold mb-3'>Users</h5>
      <ToastContainer autoClose={3000} limit={1} draggable={true} pauseOnHover={true} />
      {errorMsg && show ? (
        <Row className='justify-content-center'>
          <Col md={8} lg={6} xl={6}>
            <RbAlert variant="danger" onClose={() => setShow(false)} dismissible>
              <p className='d-flex align-items-center mb-0'>
                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x="12" y="8" x2="12.01" y2="8"></line></svg>
                {errorMsg}!
              </p>
            </RbAlert>
          </Col>
        </Row>
      ) : null}
      <>
        <Grid
          columns={columns}
          data={cachedData}
          search={true}
          language={{
            search: {
              placeholder: "Search Users"
            }
          }}
          sort={true}
          pagination={{ enabled: true, limit: 10 }}
        />
        <h5 className='fw-bold mb-3 mt-5'>Newly Registered Users</h5>
        <Grid
          columns={newUsersColumns}
          data={newUsers}
          search={true}
          language={{
            search: {
              placeholder: "Search New Users"
            }
          }}
          sort={true}
          pagination={{ enabled: true, limit: 10 }}
        />
      </>

      <Modal isOpen={statusModal} toggle={() => setStatusModal(!statusModal)}>
        <ModalHeader toggle={() => setStatusModal(!statusModal)}>Toggle Admin Status</ModalHeader>
        <ModalBody>
          {fromStatus === 2 ? "Admin status can't be changed." : `Are you sure you want to change admin status of ${selectedUser ? selectedUser.firstName : ""} from ${getStatusText(fromStatus)} to ${getStatusText(newStatus)}?`}
        </ModalBody>
        <ModalFooter>
          {fromStatus === 2 ? (
            <Button color="primary" onClick={() => setStatusModal(false)}>Okay</Button>
          ) : (
            <>
              <Button color="primary" onClick={handleAdminStatusChange}>Okay</Button>{' '}
              <Button color="secondary" onClick={() => setStatusModal(false)}>Cancel</Button>
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={actionModal} toggle={() => setActionModal(!actionModal)}>
        <ModalHeader toggle={() => setActionModal(!actionModal)}>{`${actionType.charAt(0).toUpperCase() + actionType.slice(1)} User`}</ModalHeader>
        <ModalBody>
          Are you sure you want to {actionType} {selectedUser ? selectedUser.firstName : ""}?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAction}>{`${actionType.charAt(0).toUpperCase() + actionType.slice(1)}`}</Button>{' '}
          <Button color="secondary" onClick={() => setActionModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

UserDataTable.propTypes = {
  className: PropTypes.string,
};

export default UserDataTable;
