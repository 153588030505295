import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, _ } from "gridjs-react";
import RbAlert from 'react-bootstrap/Alert';
import axios from 'axios';
import { verifyToken } from '../Common/AuthToken';

const Processes = () => {
  const [modal, setModal] = useState(false);
  const [source, setSource] = useState('');
  const [destination, setDestination] = useState('');
  const [action, setAction] = useState('');
  const [error, setError] = useState('');
  const [userData, setUserData] = useState([]);
  const [cachedData, setCachedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const storedToken = localStorage.getItem('auth_token');
  const [processData, setProcessData] = useState([]);
  const [processErrorMsg, setProcessErrorMsg] = useState("");
  const [showProcessError, setShowProcessError] = useState(false);
  const [fileTransferData, setFileTransferData] = useState([]);
  const [fileSearchError, setFileSearchError] = useState("");
  const [fileSearchQuery, setFileSearchQuery] = useState("");
  const [showFileResults, setShowFileResults] = useState(false);

  const toggleModal = () => setModal(!modal);

  const updateCache = async () => {
    try {
      const config = {
        method: 'POST',
        url: '/api/allProjects',
        headers: { 'Content-Type': 'application/json' },
        data: { user_email: userData[0]?.email || "" },
      };

      const response = await axios.request(config);
      const projectOptions = response.data.data.map((project) => (
        <option key={project.uniqueId.toLowerCase()} value={project.uniqueId.toLowerCase()}>
          {project.projectName}
        </option>
      ));
      setCachedData([
        <option key="" value="">
          Select a Project
        </option>,
        ...projectOptions,
      ]);
    } catch (error) {
      console.error('Error updating cache:', error);
    }
  };

  useEffect(() => {
    const fetchProcessData = async () => {
      try {
        const config = {
          method: 'GET',
          url: `/api/getProcessData`,
          headers: {
            'Authorization': `Bearer ${storedToken}`,
            'Content-Type': 'application/json',
          },
        };

        const response = await axios.request(config);

        if (response.data.status === 1) {
          const processDataArray = response.data.data
            .map((process) => [
              process.processId,
              process.userEmail,
              process.startTime,
              process.endTime,
              process.transferSize,
              process.numberOfFiles,
              process.projectId
            ])
            .sort((a, b) => new Date(b[2]) - new Date(a[2]));

          setProcessData(processDataArray);
        } else {
          toast.error("Failed to fetch process data.");
          setShowProcessError(true);
          setProcessErrorMsg("Failed to fetch process data");
        }
      } catch (error) {
        console.error('Error fetching process data:', error);
        toast.error("Error fetching process data.");
        setShowProcessError(true);
        setProcessErrorMsg("Error fetching process data.");
      }
    };

    fetchProcessData();
  }, [storedToken]);

  const handleFileSearch = async () => {
    try {
      const config = {
        method: 'GET',
        url: `/api/getFileTransferData?processId=${fileSearchQuery}`,
        headers: {
          'Authorization': `Bearer ${storedToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios.request(config);

      if (response.data.status === 1) {
        const fileDataArray = response.data.data.map((file) => [
          file.processId,
          file.fileId,
          file.fileName
        ]);
        setFileTransferData(fileDataArray);
        setShowFileResults(true);
      } else {
        toast.error("Failed to fetch file transfer data.");
        setFileSearchError("Failed to fetch file transfer data");
        setShowFileResults(false);
      }
    } catch (error) {
      console.error('Error fetching file transfer data:', error);
      toast.error("Error fetching file transfer data.");
      setFileSearchError("Error fetching file transfer data.");
      setShowFileResults(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = { token: storedToken };
        const tokenResponse = await verifyToken(user);
        setUserData([tokenResponse?.data]);
      } catch (error) {
        console.error('Error verifying token:', error);
        toast.error('Error fetching user data. Please try again.');
      }
    };

    fetchData();
  }, [storedToken]);

  useEffect(() => {
    if (userData.length) {
      updateCache();
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!source || !destination || !action) {
      setError('Please fill in all fields.');
      return;
    }

    setError('');

    setModal(false);

    setIsLoading(true);

    try {
      const config = {
        method: 'POST',
        url: '/api/newRcloneProcess',
        headers: { 'Content-Type': 'application/json' },
        data: {
          source,
          destination,
          action: action.toLowerCase(),
          user_email: userData[0]?.email || "",
        },
      };

      const response = await axios.request(config);
      const uniqueId = response.data.uniqueId;

      setTimeout(() => {
        toast.success(
          `Your request with process ID: ${uniqueId} has been completed. Refresh the webpage to see the process details.`
        );
        setIsLoading(false);
      }, 5000);
    } catch (error) {
      console.error('Error submitting file transfer process:', error);
      toast.error('Failed to start the process. Please try again.');
      setIsLoading(false);
    }
  };

  const processColumns = [
    "Process ID",
    "User Email",
    "Start Time",
    "End Time",
    "Transfer Size",
    "Number of Files",
    "Project ID",
  ];


  const fileColumns = [
    "Process ID",
    "File ID",
    "File Name",
  ];


  return (
    <div>
      <h5 className="fw-bold mb-3">File Transfer</h5>
      <Button color="primary" onClick={toggleModal}>
        Start File Transfer
      </Button>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Start File Transfer</ModalHeader>
        <ModalBody>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="source">Source</Label>
              <Input
                type="text"
                id="source"
                placeholder="Enter source path"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="destination">Destination</Label>
              <Input
                type="select"
                id="destination"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              >
                {cachedData}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="action">Action</Label>
              <Input
                type="select"
                id="action"
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <option value="">Select an Action</option>
                <option value="Copy">Copy</option>
                <option value="Move">Move</option>
                <option value="Sync">Sync</option>
              </Input>
            </FormGroup>
            <ModalFooter>
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>

      {}
      {isLoading && (
        <div className="text-center my-4">
          <Spinner color="primary" />
          <p>Transfer is in progress..</p>
        </div>
      )}

      <ToastContainer autoClose={3000} limit={1} draggable pauseOnHover />

    {/* File Search Section */}
    <div className="my-4">
      <h5 className="fw-bold mb-3">Search File Transfer Data by Process ID</h5>
      <Input
        type="text"
        placeholder="Enter Process ID"
        value={fileSearchQuery}
        onChange={(e) => setFileSearchQuery(e.target.value)}
        style={{ width: "300px", display: "inline-block", marginRight: "10px" }}
      />
      <Button color="primary" onClick={handleFileSearch}>Search</Button>

      {fileSearchError && (
        <div className="alert alert-danger mt-3" role="alert">
          {fileSearchError}
        </div>
      )}

      {showFileResults && (
        <div className="mt-4">
          <Grid
            columns={fileColumns}
            data={fileTransferData}
            search={{
              placeholder: "Search File Transfer Data",
            }}
            sort
            pagination={{
              enabled: true,
              limit: 10,
            }}
          />
        </div>
      )}
    </div>
      <h5 className="fw-bold mb-3">Processes Data</h5>
      <ToastContainer autoClose={3000} limit={1} draggable pauseOnHover />

      {showProcessError && (
        <RbAlert variant="danger" onClose={() => setShowProcessError(false)} dismissible>
          {processErrorMsg}
        </RbAlert>
      )}

      <Grid
        columns={processColumns}
        data={processData}
        search={{
          placeholder: "Search Process Data",
        }}
        sort
        pagination={{
          enabled: true,
          limit: 10,
        }}
      />
    </div>
  );
};

export default Processes;
